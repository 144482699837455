/*****************************************************************
   - Imports
   - Off-canvas mobile menu
   - Change nav background on scroll
   - Expand search input
   - Smooth scroll links in product sidebar
   - Contact form goodies
   - Login form
   - Float form labels 
   - Open modal from select
*****************************************************************/



// Imports
// ------------------------------------------------------------
// import 'foundation-sites/js/foundation';
import './jquery-global';
import '../../node_modules/@accessible360/accessible-slick/slick/slick';
import '../../node_modules/jquery-cycle2-ibe/build/jquery.cycle2';
import '../../node_modules/jquery-cycle2-ibe/build/plugin/jquery.cycle2.center.min.js';
import UIkit from 'uikit';
import MicroModal from 'micromodal';
MicroModal.init();

// $(document).foundation();



// Off-canvas mobile menu
// ------------------------------------------------------------
$('.js-open-nav').on('click', function(){
   // console.log('ouch');
   $('.nav').addClass('is-open');
   $('body').addClass('has-overlay');
});

// $('.js-close-nav, #page-content').on('click', function(){
   $('.js-close-nav').on('click', function(){
      // console.log('closed!');
      $('.nav').removeClass('is-open');
      $('body').removeClass('has-overlay');
});



// Change nav background on scroll
// ------------------------------------------------------------
const header = document.querySelector(".nav");
const sectionOne = document.querySelector(".c-hero"); // this is what we're watching

const sectionOneOptions = {
   rootMargin: "-200px 0px 0px 0px"
};

const sectionOneObserver = new IntersectionObserver(function(
   entries, 
   sectionOneObserver
) {
   entries.forEach(entry => {
      // console.log(entry.target);
      if(!entry.isIntersecting) {
         header.classList.add('red');
      } else {
         header.classList.remove('red');
      }
   })
}, sectionOneOptions);

sectionOneObserver.observe(sectionOne);




// Expand search input
// ------------------------------------------------------------
$(document).ready(function() {
	var submitIcon = $('.exp-icon');
	var submitInput = $('.exp-input');
	var searchBox = $('.expand');
	var isOpen = false;

	$(document).on('mouseup', function(){
		if (isOpen == true) {
         submitInput.val('');
         $('.exp-submit').css('z-index','-999');
         submitIcon.click();
		}
	});

	submitIcon.on('mouseup', function(){
		return false;
	});

	searchBox.on('mouseup', function(){
		return false;
	});

	submitIcon.on('click', function(){
		if(isOpen == false){
			searchBox.addClass('exp-open');
			isOpen = true;
			submitInput.focus();
		} else {
			searchBox.removeClass('exp-open');
			isOpen = false;
		}
	});
});



// Smooth scroll links in product sidebar
// ------------------------------------------------------------
$(function() {
  $('a.scroll').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top-200
        }, 1000);
        return false;
      }
    }
  });
});



// Contact form goodies
// ------------------------------------------------------------
$('#firstName, #lastName').bind('keypress keyup blur', function() {
	var t1 = $("#firstName").val();
	var t2 = $("#lastName").val();
	var result = (t1 || "") + ' ' + (t2 || ""); // Logical OR `||` to avoid null/undefined.

	//concatenated_string = $("#firstName").val() + ' ' + $("#lastName").val();
    $('#fullName').val(result);
    $('#fromName').val(result);
});

$('#fromEmail').bind('keypress keyup blur', function() {
    $('#emailAddress').val($(this).val());
});



// Login form
// ------------------------------------------------------------
$('#loginForm').submit(function(e) {
//   e.preventDefault();
  $('#loginForm .login-btn').val('One Moment...');
});



// Float form labels 
// ------------------------------------------------------------
$(function(){
  var onClass = "on";
  var showClass = "show";
  
  $("input, textarea").bind("checkval",function(){
    var label = $(this).prev("label");
    if(this.value !== ""){
      label.parent().addClass(showClass);
    } else {
      label.parent().removeClass(showClass);
    }
  }).on("keyup",function(){
    $(this).trigger("checkval");
  }).on("focus",function(){
    $(this).prev("label").addClass(onClass);
  }).on("blur",function(){
      $(this).prev("label").removeClass(onClass);
  }).on("change",function(){
      $(this).prev("label").parent().addClass(onClass);
  }).trigger("checkval");
  
  $("select").bind("checkselectval",function(){
    var label = $(this).prev("label");
    if(this.value !== ""){
      label.parent().addClass(showClass);
    } else {
      label.parent().removeClass(showClass);
    }
  }).on("change",function(){
    $(this).trigger("checkselectval");
  }).trigger("checkselectval");
  
});


// Open modal from select
// ------------------------------------------------------------
$('.region-selector').on('change', function() {
   var stateId = $(this).val();
   // console.log(stateId);
   MicroModal.show(stateId);
});